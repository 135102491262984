import {Injectable} from '@angular/core';
import {Asset, AssetEntryNewDto, AssetInput, DataAddress} from '../../edc-dmgmt-client';
import {AssetEditorDialogFormValue} from '../components/asset-editor-dialog/asset-editor-dialog-form-model';
import {AssetPropertyMapper} from './asset-property-mapper';
import {HttpRequestParamsMapper} from './http-params-mapper.service';

@Injectable()
export class AssetEntryBuilder {
  constructor(
    private assetPropertyMapper: AssetPropertyMapper,
    private httpRequestParamsMapper: HttpRequestParamsMapper,
  ) {}

  /**
   * Build {@link AssetEntryNewDto} from {@link AssetEditorDialogFormValue}
   *
   * @param formValue form value
   * @return asset create dto
   */
  buildAssetEntry(formValue: AssetEditorDialogFormValue): AssetInput {
    let properties = this.assetPropertyMapper.buildProperties(formValue);
    let dataAddress = this.buildDataAddressDto(formValue.datasource);
    const context = {
      "edc": 'https://w3id.org/edc/v0.0.1/ns/'
    }
    dataAddress = {...dataAddress, ['@type']: 'DataAddress', type: formValue?.datasource?.dataAddressType};
    return {properties, dataAddress, ['@context']: context, ['@id']: properties['edc:id']};
  }

  private buildDataAddressDto(
    datasource: AssetEditorDialogFormValue['datasource'],
  ): any {
    switch (datasource?.dataAddressType) {
      case 'Custom-Data-Address-Json':
        return JSON.parse(datasource.dataDestination?.trim() ?? '');
      case 'Http':
        return this.httpRequestParamsMapper.buildHttpDataAddressDto(datasource);
      case 'AmazonS3':
        return JSON.parse(datasource.S3DataSource?.trim() ?? '');
      case 'AzureStorage':
        return JSON.parse(datasource.AzureStorageDataSource?.trim() ?? '');
      default:
        throw new Error(
          `Invalid data address type: ${datasource?.dataAddressType}`,
        );
    }
  }
}
