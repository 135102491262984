import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {first, map, switchMap} from 'rxjs/operators';
import {PolicyDefinitionOutput, PolicyDefinitionService} from '../../../edc-dmgmt-client';
import {Fetched} from '../../models/fetched';
import {NotificationService} from '../../services/notification.service';
import {NewPolicyDialogResult} from '../new-policy-dialog/new-policy-dialog-result';
import {NewPolicyDialogComponent} from '../new-policy-dialog/new-policy-dialog.component';
import {PolicyCard} from '../policy-cards/policy-card';
import {PolicyCardBuilder} from '../policy-cards/policy-card-builder';
import {Operator} from "src/modules/edc-demo/services/policy-type-ext";

export interface PolicyList {
  policyCards: PolicyCard[];
  numTotalPolicies: number;
}

@Component({
  selector: 'app-policy-view',
  templateUrl: './policy-view.component.html',
  styleUrls: ['./policy-view.component.scss'],
})
export class PolicyViewComponent implements OnInit {
  policyList: Fetched<PolicyList> = Fetched.empty();
  searchText: string = '';
  deleteBusy = false;
  private fetch$ = new BehaviorSubject(null);

  constructor(
    private PolicyDefinitionService: PolicyDefinitionService,
    private notificationService: NotificationService,
    private policyCardBuilder: PolicyCardBuilder,
    private readonly dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.fetch$
      .pipe(
        switchMap(() => {
          return this.PolicyDefinitionService.queryPolicyDefinitions().pipe(
            map(
              (policyDefinitions: PolicyDefinitionOutput[]): any => {
              const updatedPolicyDefinition = policyDefinitions.map((item) => this.updateResponseObject(item) );
              return {
                policyCards: this.policyCardBuilder.buildPolicyCards(
                  this.filterPolicies(updatedPolicyDefinition), this.filterPolicies(policyDefinitions)
                ),
                numTotalPolicies: policyDefinitions.length,
              };}
            ),
            Fetched.wrap({
              failureMessage: 'Failed fetching policies.',
            }),
          );
        }),
      )
      .subscribe((policyList) => (this.policyList = policyList));
  }
  
  //todo Updating policy object with new response from tractusx
  updateResponseObject(policyDefinitions: any) {
  return {
    createdAt: policyDefinitions?.['edc:createdAt'],
    id:  policyDefinitions?.['@id'],
    policy: {
      permissions: this.updateTypeObject(policyDefinitions?.['edc:policy']?.['odrl:permission'], 'permission') || [],
      prohibitions: this.updateTypeObject(policyDefinitions?.['edc:policy']?.['odrl:prohibition'], 'prohibition') || [],
      obligations: this.updateTypeObject(policyDefinitions?.['edc:policy']?.['odrl:obligation'], 'obligation') || [],
      extensibleProperties: {},
      inheritsFrom: null,
      assigner: null,
      assignee: null,
      target: null,
      "@type": {
        "@policytype": policyDefinitions?.['edc:policy']?.['@type'].substring(5).toLowerCase()
      }
    }
  }
  }
  
  updateTypeObject(policyTypeObject: any, edctype: string) {
  return [
    {
      edctype: "dataspaceconnector:"+edctype,
      uid: null,
      target: "",
      action: {
        type: policyTypeObject?.['odrl:action']?.['odrl:type']?.substring(5),
        includedIn: null,
        constraint: null
      },
      assignee: null,
      assigner: null,
      constraints: Array.isArray(policyTypeObject?.['odrl:constraint']?.['odrl:or']) ? policyTypeObject?.['odrl:constraint']?.['odrl:or'].map((constraint: any) => {
      return {
        edctype: "dataspaceconnector:"+edctype,
        leftExpression: constraint?.['odrl:leftOperand'],
        rightExpression: constraint?.['odrl:rightOperand'],
        operator: constraint?.['odrl:operator']?.['@id'].substring(5).toUpperCase(),
      }
    }) : [{
        edctype: "dataspaceconnector:"+edctype,
        leftExpression: policyTypeObject?.['odrl:constraint']?.['odrl:or']?.['odrl:leftOperand'],
        rightExpression: policyTypeObject?.['odrl:constraint']?.['odrl:or']?.['odrl:rightOperand'],
        operator: policyTypeObject?.['odrl:constraint']?.['odrl:or']?.['odrl:operator']?.['@id'].substring(5).toUpperCase(),
      }] ,
      duties: []
    }
  ]
  }

  onSearch() {
    this.refresh();
  }

  onCreate() {
    const dialogRef = this.dialog.open(NewPolicyDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((result: NewPolicyDialogResult) => {
        if (result.refreshList) {
          this.refresh();
        }
      });
  }

  refresh() {
    this.fetch$.next(null);
  }

  private filterPolicies(
    policyDefinitions: any,
  ): any[] {
    return policyDefinitions.filter((policy: any) =>
      this.isFiltered(policy, this.searchText),
    );
  }

  /**
   * simple full-text search - serialize to JSON and see if "searchText"
   * is contained
   */
  private isFiltered(policy: PolicyDefinitionOutput, searchText: string) {
    return JSON.stringify(policy).includes(searchText);
  }
}
