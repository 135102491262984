<mat-form-field class="grow form-field" color="accent">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="control" [compareWith]="compareWith">
    <mat-option [value]="null">-</mat-option>
    <mat-option *ngFor="let item of items.highlightItems" [value]="item">
      {{ item.label }}
    </mat-option>
    <mat-divider
      *ngIf="
        items.highlightItems.length && items.otherItems.length
      "></mat-divider>
    <mat-option *ngFor="let item of items.otherItems" [value]="item">
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
