/**
 * EDC REST API
 * All files merged by open api merger
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Policy } from './policy';
import {PolicyDefinitionOutput} from "src/modules/edc-dmgmt-client/model/policyDefinitionOutput";


export interface PolicyDefinition {
    policy: object;
    uid?: string;
    id?: string;
  ['@id']?: string;
  ['@type']?: string;
  ['@context']?: object;
}

/**
 * Temporary workaround around EDC Milestone 6/7 API incompatibilities.
 * @param policyDefinition polcicy definition
 * @return policy definition ID
 */
export function policyDefinitionId(policyDefinition: PolicyDefinitionOutput): string {
  return policyDefinition['id'] || policyDefinition['@id'] || (policyDefinition as any).uid || 'no-known-id-field-on-policy-definition';
}
