<div class="mat-card-header">
  <mat-icon style="font-size: 40px; width: 40px; height: 40px; min-width: 40px">
    sim_card
  </mat-icon>
  <div class="mat-card-header-text">
    <div class="mat-card-title">Fetch Status</div>
    <div class="mat-card-subtitle">Other Connector Endpoint Catalogs</div>
  </div>
</div>

<div mat-dialog-content>
  <ul>
    <li *ngFor="let request of data?.requests">
      {{ request.url }}
      <ul>
        <li *ngIf="!request.data.isError">
          {{ request.data.state }}
        </li>
        <li *ngFor="let errorMessage of errorMessages(request.data)">
          {{ errorMessage }}
        </li>
      </ul>
    </li>
  </ul>
</div>

<div class="w-full flex flex-row justify-end" mat-dialog-actions>
  <button mat-dialog-close mat-button color="default">Cancel</button>
  <button
    mat-button
    mat-flat-button
    color="primary"
    (click)="dialogData.refresh()">
    Refresh
  </button>
</div>
