<div id="wrapper">
  <button mat-raised-button color="primary" (click)="loadTransferProcesses()">
    <mat-icon white-icon>cached</mat-icon>
    Refresh
  </button>
  <mat-paginator
    *ngIf="transferProcessesList.isReady"
    [hidePageSize]="true"
    [disabled]="true"
    [pageSize]="transferProcessesList.data.transferProcesses.length"
    [length]="
      transferProcessesList.data.transferProcesses.length
    "></mat-paginator>
  <div class="flex flex-row justify-center">
    <edc-demo-loading-state
      *ngIf="transferProcessesList.isLoading"
      class="min-h-[35rem]"></edc-demo-loading-state>
    <edc-demo-error-state
      *ngIf="transferProcessesList.isError"
      class="min-h-[35rem]"
      [errorMessage]="
        transferProcessesList.error.failureMessage
      "></edc-demo-error-state>
    <edc-demo-empty-state
      *ngIf="
        transferProcessesList.isReady &&
        !transferProcessesList.data.transferProcesses.length
      "
      class="min-h-[35rem]"
      [emptyMessage]="'No transfer history found.'"></edc-demo-empty-state>
  </div>
  <div
    *ngIf="
      transferProcessesList.isReady &&
      !!transferProcessesList.data.transferProcesses.length
    ">
    <table
      class="transfer-history-table"
      mat-table
      [dataSource]="transferProcessesList.data.transferProcesses">
      <ng-container matColumnDef="id">
        <th *matHeaderCellDef mat-header-cell scope="col">Id</th>
        <td *matCellDef="let item" mat-cell>{{ item['@id'] }}</td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th *matHeaderCellDef mat-header-cell scope="col">Created</th>
        <td *matCellDef="let item" mat-cell>
          {{ asDate(item.createdTimestamp) || asDate(item['edc:stateTimestamp']) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th *matHeaderCellDef mat-header-cell scope="col">State</th>
        <td *matCellDef="let item" mat-cell>{{ item['edc:state'] }}</td>
      </ng-container>

      <ng-container matColumnDef="lastUpdated">
        <th *matHeaderCellDef mat-header-cell scope="col">Last updated</th>
        <td *matCellDef="let item" mat-cell>
          {{ asDate(item['edc:stateTimestamp']) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="connectorId">
        <th *matHeaderCellDef mat-header-cell scope="col">ConnectorId</th>
        <td *matCellDef="let item" mat-cell>
          {{ item['edc:connectorId'] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="assetId">
        <th *matHeaderCellDef mat-header-cell scope="col">AssetId</th>
        <td *matCellDef="let item" mat-cell>
          {{ item['edc:assetId'] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="contractId">
        <th *matHeaderCellDef mat-header-cell scope="col">ContractId</th>
        <td *matCellDef="let item" mat-cell>
          {{ item['edc:contractId'] }}
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="columns"
        class="transfer-history-table-header"
        mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns" mat-row></tr>
    </table>
  </div>
</div>
