import {Injectable} from '@angular/core';
import {TransferProcess} from '../../edc-dmgmt-client';

@Injectable({providedIn: 'root'})
export class TransferProcessUtils {
  isIncoming(transferProcess: TransferProcess): boolean {
    return transferProcess['edc:type'] === 'CONSUMER';
  }

  isOutgoing(transferProcess: TransferProcess): boolean {
    return !this.isIncoming(transferProcess);
  }
}
