import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AssetService} from '../../edc-dmgmt-client';
import {Asset} from '../models/asset';
import {AssetPropertyMapper} from './asset-property-mapper';

/**
 * Wrapped AssetService with AssetPropertyMapper
 */
@Injectable({
  providedIn: 'root',
})
export class AssetServiceMapped {
  constructor(
    private assetPropertyMapper: AssetPropertyMapper,
    private assetService: AssetService,
  ) {}

  fetchAssets(): Observable<Asset[]> {
    return this.assetService
      .requestAssets1()
      .pipe(
        map((assets: any) =>
          assets.map((asset: any) => {
            const responseObj = { ...asset['edc:dataAddress'], ...asset['edc:properties'],...asset['edc:privateProperties']}
           return this.assetPropertyMapper.readProperties(responseObj);
          }),
        ),
      );
  }
}
