import {Injectable} from '@angular/core';
import {
  ContractDefinitionInput,
  Criterion,
  policyDefinitionId, PolicyDefinitionInput, PolicyDefinitionOutput,
} from '../../../edc-dmgmt-client';
import {Asset} from '../../models/asset';
import {AssetProperties} from '../../services/asset-properties';
import {PolicyDefinitionUtils} from '../../services/policy-definition-utils';
import {Operator, OperatorSymbols} from '../../services/policy-type-ext';
import {associateBy} from '../../utils/map-utils';
import {assetSearchTargets} from '../../utils/search-utils';
import {
  ContractDefinitionCard,
  ContractDefinitionCardCriterionValue,
  ContractDefinitionCardPolicy,
} from './contract-definition-card';

@Injectable({providedIn: 'root'})
export class ContractDefinitionCardBuilder {
  constructor(private policyDefinitionUtils: PolicyDefinitionUtils) {}

  buildContractDefinitionCards(
    contractDefinitions: ContractDefinitionInput[],
    assets: Asset[],
    policyDefinitions: PolicyDefinitionInput[],
  ): ContractDefinitionCard[] {
    const assetById = associateBy(assets, (asset) => asset.id);
    const policyDefinitionById = associateBy(
      policyDefinitions,
      policyDefinitionId,
    );
    return contractDefinitions.map((contractDefinition) =>
      this.buildContractDefinitionCard(
        contractDefinition,
        assetById,
        policyDefinitionById,
      ),
    );
  }

  buildContractDefinitionCard(
    contractDefinition: ContractDefinitionInput,
    assetById: Map<string, Asset>,
    policyDefinitionById: Map<string, PolicyDefinitionOutput>,
  ): ContractDefinitionCard {
    const criteria = [contractDefinition['edc:assetsSelector']] as Criterion[];
    return {
      id: contractDefinition['@id'] || "",
      contractPolicy: this.extractPolicy(
        contractDefinition['edc:contractPolicyId'] || "",
        policyDefinitionById,
      ),
      accessPolicy: this.extractPolicy(
        contractDefinition['edc:accessPolicyId']   || "",
        policyDefinitionById,
      ),
      criteria: criteria.map((criterion: Criterion) => ({
        label: this.extractCriterionOperation(criterion),
        values: this.extractCriterionValues(criterion, assetById),
      })),
      detailJsonObj: contractDefinition,
    };
  }

  private extractPolicy(
    policyDefinitionId: string,
    policyDefinitionsById: Map<string, PolicyDefinitionOutput>,
  ): ContractDefinitionCardPolicy {
    return {
      policyDefinitionId: policyDefinitionId,
      policyDefinition: policyDefinitionsById.get(policyDefinitionId) || null,
    };
  }

  private extractCriterionOperation(criterion: Criterion): string {
    const operandLeft = criterion['edc:operandLeft'] || "";
    // @ts-ignore
    const operandLeftString: string = operandLeft.toLowerCase();
    if (
      operandLeftString === AssetProperties.id &&
      (criterion['edc:operator']?.toUpperCase() === OperatorSymbols.EQ ||
        criterion['edc:operator']?.toUpperCase() === OperatorSymbols.IN)
    ) {
      return 'Assets';
    }

    let operator =
      OperatorSymbols[criterion['edc:operator'] as Operator] ?? criterion['edc:operator'];
    return `${operandLeft} ${operator}`;
  }

  private extractCriterionValues(
    criterion: Criterion,
    assetsById: Map<string, Asset>,
  ): ContractDefinitionCardCriterionValue[] {
    let {operandRight, operandLeft} = {operandRight: criterion['edc:operandRight'], operandLeft: criterion['edc:operandLeft']};

    let values: (object | string)[] = [];
    if (Array.isArray(values)) {
      values = [operandRight || ''] as string[];
    } else if (typeof operandRight === 'string') {
      values = [operandRight];
    } else {
      values = [operandRight || ''];
    }
    
    return values.map((it) => {
      if (typeof it === 'string') {
        const stringType: ContractDefinitionCardCriterionValue = {
          type: 'string',
          value: it,
          searchTargets: [it],
        };

        // Try find asset
        // @ts-ignore
        if (operandLeft === AssetProperties.id) {
          let asset = assetsById.get(it);
          if (asset) {
            return {
              type: 'asset',
              asset,
              searchTargets: assetSearchTargets(asset),
            };
          }
        }

        return stringType;
      }

      // fall back to JSON for generic objects
      return {type: 'json', json: it, searchTargets: [JSON.stringify(it)]};
    });
  }
}
