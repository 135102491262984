import {Injectable} from '@angular/core';
import {addDays} from 'date-fns';
import {Permission, PolicyDefinition} from '../../edc-dmgmt-client';
import {NewPolicyDialogFormValue} from '../components/new-policy-dialog/new-policy-dialog-form-model';
import {PolicyDefinitionUtils} from './policy-definition-utils';
import {ExpressionLeftSideConstants} from './policy-type-ext';

@Injectable({
  providedIn: 'root',
})
export class PolicyDefinitionBuilder {
  constructor(private policyDefinitionUtils: PolicyDefinitionUtils) {}

  /**
   * Build {@link PolicyDefinition} from {@link NewPolicyDialogFormValue}
   *
   * @param formValue {@link NewPolicyDialogFormValue}
   */
  buildPolicyDefinition(formValue: NewPolicyDialogFormValue): PolicyDefinition {
    return {
      ['@id']: formValue.id?.trim()!,
      ['@type']: "PolicyDefinitionRequestDto",
      ['@context']: {
        odrl: "http://www.w3.org/ns/odrl/2/"
      },
      policy: {
        ["@type"]: "dataspaceconnector:permission",
        ["odrl:permission"]: [{"odrl:action" : "USE", ...this.buildPolicyPermissions(formValue)}],
      },
    };
  }

  private buildPolicyPermissions(
    formValue: NewPolicyDialogFormValue,
  ): object {
    let policyType = formValue.policyType;
    switch (policyType) {
      case 'Time-Period-Restricted':
        return this.buildTimePeriodRestrictionPermissions(formValue);
      case 'Connector-Restricted-Usage':
        return this.buildConnectorRestrictedUsagePermissions(formValue);
      default:
        throw new Error(`Unknown policyType: ${policyType}`);
    }
  }

  private buildConnectorRestrictedUsagePermissions(
    formValue: NewPolicyDialogFormValue,
  ): object {
    return this.policyDefinitionUtils.buildPermission({
        ["odrl:or"]: [
          this.policyDefinitionUtils.buildAtomicConstraint(
            'REFERRING_CONNECTOR',
            "eq",
            formValue.connectorId!,
          ),
        ],
      });
  }

  private buildTimePeriodRestrictionPermissions(
    formValue: NewPolicyDialogFormValue,
  ): object {
    const start = formValue.range!!.start!!;
    const end = addDays(formValue.range!!.end!!, 1);

    return this.policyDefinitionUtils.buildPermission({
        ["odrl:or"]: [
          this.policyDefinitionUtils.buildAtomicConstraint(
            ExpressionLeftSideConstants.PolicyEvaluationTime,
            'gteq',
            start.toISOString()!,
          ),
          this.policyDefinitionUtils.buildAtomicConstraint(
            ExpressionLeftSideConstants.PolicyEvaluationTime,
            'lt',
            end.toISOString()!,
          ),
        ],
      });
  }
}
