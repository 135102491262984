import {KeycloakService} from 'keycloak-angular';

export class FetchToken {
  static readonly type = '[client] get token';
  constructor(public keycloak: KeycloakService) {}
}

export class UpdateToken {
  static readonly type = '[client] update token';
  constructor(public keycloak: KeycloakService) {}
}

export class SetExpirationTime {
  static readonly type = '[client] set expiration time';
  constructor(public jwt: string) {}
}

export class Logout {
  static readonly type = '[client] logout';
  constructor(public keycloak: KeycloakService) {}
}
