import {Injectable} from '@angular/core';
import {PolicyDefinition, policyDefinitionId, PolicyDefinitionOutput} from '../../../edc-dmgmt-client';
import {PolicyDefinitionUtils} from '../../services/policy-definition-utils';
import {
  AtomicConstraint,
  LiteralExpression,
  OperatorSymbols,
} from '../../services/policy-type-ext';
import {PolicyCard, PolicyCardConstraint} from './policy-card';
import _default from "chart.js/dist/plugins/plugin.tooltip";
import numbers = _default.defaults.animations.numbers;

@Injectable({providedIn: 'root'})
export class PolicyCardBuilder {
  constructor(private policyDefinitionUtils: PolicyDefinitionUtils) {}

  buildPolicyCards(policyDefinitions: PolicyDefinitionOutput[], originalPolicyDefinitions: PolicyDefinitionOutput[]): PolicyCard[] {
    return policyDefinitions.map((policyDefinition, key) =>
      this.buildPolicyCard(policyDefinition, originalPolicyDefinitions[key]),
    );
  }

  buildPolicyCard(policyDefinition: PolicyDefinitionOutput, originalPolicyDefinition: PolicyDefinitionOutput): PolicyCard {
    const irregularities =
      this.policyDefinitionUtils.getPolicyIrregularities(policyDefinition);
    return {
      id: policyDefinitionId(policyDefinition),
      isRegular: !!irregularities.length,
      irregularities,
      constraints: this.buildPolicyCardConstraints(policyDefinition),
      objectForJson: originalPolicyDefinition,
    };
  }

  private buildPolicyCardConstraints(
    policyDefinition: PolicyDefinitionOutput,
  ): PolicyCardConstraint[] {
   // @ts-ignore
  const permissions = policyDefinition.policy?.permissions;
    const constraints: AtomicConstraint[] =
      permissions
        ?.map((it:any) => (it?.constraints ?? []) as AtomicConstraint[])
        ?.flat()
        ?.filter((constraint:any) =>
          this.policyDefinitionUtils.isPermission(constraint),
        ) ?? [];
    return constraints.map((constraint: any) => {
    console.log(constraint, 'cons')
      let rightStringValue = constraint.rightExpression;
      let leftStringValue = constraint.leftExpression;

// @ts-ignore
      const operator = OperatorSymbols[constraint.operator] ?? constraint.operator
      return {
        left: leftStringValue,
        operator: operator,
        right: rightStringValue,
      };
    });
  }
}
