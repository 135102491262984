import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {AppConfigService} from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigFetcher {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
  ) {}

  /**
   * Fetch app-config.json.
   */
  fetchConfigJson(): Promise<Record<string, string | null>> {
    return firstValueFrom(
      this.http.get<Record<string, string | null>>(
        '/assets/config/app-config.json',
      ),
    );
  }

  fetchConfigFromApi(url: string, token: string) {
    return this.http.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
