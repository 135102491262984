import {Constraint} from '../../edc-dmgmt-client';

export const ExpressionLeftSideConstants = {
  PolicyEvaluationTime: 'POLICY_EVALUATION_TIME',
};

export const EdcTypes = {
  AtomicConstraint: 'AtomicConstraint',
  LiteralExpression: 'dataspaceconnector:literalexpression',
  Permission: 'dataspaceconnector:permission',
};

export const OperatorSymbols = {
  GT: '>',
  LT: '<',
  EQ: '=',
  NEQ: '≠',
  GTEQ: '≥',
  LTEQ: '≤',
  GEQ: '≥',
  LEQ: '≤',
  IN: '∈',
};

export type Operator = keyof typeof OperatorSymbols;

export interface AtomicConstraint extends Constraint {
  type?: string;
  leftExpression?: unknown;
  rightExpression?: unknown;
  operator?: Operator | string;
  ['@type']?: string,
  ['odrl:leftExpression']?: unknown,
  ['odrl:rightExpression']?: unknown,
  ['odrl:operator']?: Operator | string,
}

export interface LiteralExpression {
  edctype: string;
  value: string;
}
