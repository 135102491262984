import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {
  ContractNegotiationService as ContractNegotiationApiService,
  ContractNegotiation,
  InitiateEdrNegotiationRequest,
  TransferProcess,
  TransferProcessService,
  TransferRequest,
} from '../../edc-dmgmt-client';
import {ContractOffer} from '../models/contract-offer';
import {NegotiationResult} from '../models/negotiation-result';
import {TransferProcessStates} from '../models/transfer-process-states';
import {NotificationService} from './notification.service';
import {AppConfigService} from "src/modules/app/config/app-config.service";

interface RunningTransferProcess {
  processId: string;
  assetId?: string;
  state: TransferProcessStates;
}

@Injectable({providedIn: 'root'})
export class ContractNegotiationService {
  runningTransferProcesses: RunningTransferProcess[] = [];
  // contractOfferId, NegotiationResult
  runningNegotiations: Map<string, NegotiationResult> = new Map<
    string,
    NegotiationResult
  >();
  // contractOfferId, contractAgreementId
  finishedNegotiations: Map<string, ContractNegotiation> = new Map<
    string,
    ContractNegotiation
  >();
  private pollingHandleNegotiation?: any;

  constructor(
    private contractNegotiationService: ContractNegotiationApiService,
    private transferProcessService: TransferProcessService,
    private router: Router,
    private notificationService: NotificationService,
    private appConfigService: AppConfigService
  ) {
    if (!environment.production) {
      // Test data on local dev
      // this.runningNegotiations.set(
      //   'offer:6db25605-cd8c-4528-ade2-6a90349d06ac',
      //   {} as any,
      // );
      // this.finishedNegotiations.set(
      //   'offer:6db25605-cd8c-4528-ade1-6a90389d06ac',
      //   {} as any,
      // );
    }
  }

  updateNegotiationList() {}

  isBusy(contractOffer: ContractOffer) {
    return (
      this.runningNegotiations.get(contractOffer.id) !== undefined ||
      !!this.runningTransferProcesses.find(
        (tp) => tp.assetId === contractOffer.id,
      )
    );
  }

  isNegotiated(contractOffer: ContractOffer) {
    return this.finishedNegotiations.get(contractOffer.id) !== undefined;
  }

  getState(contractOffer: ContractOffer): string {
    const transferProcess = this.runningTransferProcesses.find(
      (tp) => tp.assetId === contractOffer.id,
    );
    if (transferProcess) {
      return TransferProcessStates[transferProcess.state];
    }

    const negotiation = this.runningNegotiations.get(contractOffer.id);
    if (negotiation) {
      return 'negotiating';
    }

    return '';
  }

  negotiate(contractOffer: any) {
    const initiateRequest: any = {
      "@context": {
        odrl: "http://www.w3.org/ns/odrl/2/"
      },
      "@type": "NegotiationInitiateRequestDto",
      connectorAddress: contractOffer.originator || '',
      protocol: "dataspace-protocol-http",
      connectorId: contractOffer.participantId || '',
      providerId: contractOffer.participantId || '',
      offer: {
        offerId: contractOffer.policy['@id'],
        assetId: contractOffer.id,
        policy: contractOffer.policy,
      },
    };
    const finishedNegotiationStates = ['CONFIRMED', 'DECLINED', 'ERROR', 'TERMINATED', 'VERIFIED'];

    this.initiateNegotiation(initiateRequest).subscribe(
      (negotiationId: any) => {
        this.finishedNegotiations.delete(initiateRequest.offer.assetId);
        this.runningNegotiations.set(initiateRequest.offer.assetId, {
          id: negotiationId['@id'],
          offerId: initiateRequest.offer.assetId,
        });

        if (!this.pollingHandleNegotiation) {
          // there are no active negotiations
          this.pollingHandleNegotiation = setInterval(() => {
            for (const negotiation of this.runningNegotiations.values()) {
              this.getNegotiationState(negotiation.id).subscribe(
                (updatedNegotiation: any) => {
                  if (
                    finishedNegotiationStates.includes(updatedNegotiation['edc:state'] || "")
                  ) {
                    let offerId = negotiation.offerId;
                    this.runningNegotiations.delete(offerId);
                    if (updatedNegotiation['edc:state'] === 'VERIFIED') {
                      updatedNegotiation.id = updatedNegotiation['@id'];
                      updatedNegotiation.contractAgreementId = updatedNegotiation['edc:contractAgreementId'];
                    
                      this.finishedNegotiations.set(offerId, updatedNegotiation);
                      
                      this.notificationService.showInfo(
                        'Contract Negotiation complete!',
                        'Show me!',
                        () => {
                          this.router.navigate(['/contracts']);
                        },
                      );
                      } else if (updatedNegotiation['edc:state'] === 'TERMINATED') {
                          this.notificationService.showError('Negotiation terminated');
                      }
                    }
  
                    if (this.runningNegotiations.size === 0) {
                      clearInterval(this.pollingHandleNegotiation);
                      this.pollingHandleNegotiation = undefined;
                    }
                  },
                );
            }
          }, 1000);
        }
      },
      (error) => {
        console.error(error);
        this.notificationService.showError('Error starting negotiation');
      },
    );
  }

  private initiateTransfer(
    transferRequest: TransferRequest,
  ): Observable<string> {
    return this.transferProcessService
      .initiateTransferProcess(transferRequest)
      .pipe(map((t) => t.id!));
  }

  getTransferProcessesById(id: string): Observable<TransferProcess> {
    return this.transferProcessService.getTransferProcess(id);
  }

  private initiateNegotiation(
    initiate: InitiateEdrNegotiationRequest,
  ): Observable<string> {
    return this.contractNegotiationService
      .initiateContractNegotiation(initiate, 'body', false);
  }

  private getNegotiationState(id: string): Observable<ContractNegotiation> {
    return this.contractNegotiationService.getNegotiation(id);
  }

  private getAgreementForNegotiation(
    contractId: string,
  ): Observable<ContractNegotiation> {
    return this.contractNegotiationService.getAgreementForNegotiation(
      contractId,
    );
  }
}
