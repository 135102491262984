import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {
  TransferProcessService,
  DataAddress,
} from '../../../edc-dmgmt-client';
import {AssetEntryBuilder} from '../../services/asset-entry-builder';
import {HttpRequestParamsMapper} from '../../services/http-params-mapper.service';
import {NotificationService} from '../../services/notification.service';
import {ValidationMessages} from '../../validators/validation-messages';
import {ContractAgreementTransferDialogData} from './contract-agreement-transfer-dialog-data';
import {ContractAgreementTransferDialogForm} from './contract-agreement-transfer-dialog-form';
import {ContractAgreementTransferDialogFormValue} from './contract-agreement-transfer-dialog-form-model';
import {ContractAgreementTransferDialogResult} from './contract-agreement-transfer-dialog-result';

@Component({
  selector: 'edc-demo-contract-agreement-transfer-dialog',
  templateUrl: './contract-agreement-transfer-dialog.component.html',
  providers: [ContractAgreementTransferDialogForm, AssetEntryBuilder],
})
export class ContractAgreementTransferDialog implements OnDestroy {
  loading = false;

  methods = ['POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS', 'HEAD'];

  constructor(
    public form: ContractAgreementTransferDialogForm,
    public validationMessages: ValidationMessages,
    private dialogRef: MatDialogRef<ContractAgreementTransferDialog>,
    private TransferProcessService: TransferProcessService,
    private notificationService: NotificationService,
    private httpRequestParamsMapper: HttpRequestParamsMapper,
    @Inject(MAT_DIALOG_DATA) private data: ContractAgreementTransferDialogData,
  ) {}

  onSave() {
    if (this.loading && !this.form.all.valid) {
      return;
    }
    this.loading = true;
    this.form.all.disable();
    
    this.TransferProcessService
      .initiateTransferProcess(
        {
          '@context': {
            odrl: "http://www.w3.org/ns/odrl/2/"
          },
          assetId: this.data.assetId,
          contractId: this.data.contractId,
          dataDestination: this.buildDataAddressDto(this.form.value),
          connectorAddress: this.data.connectorAddress,
          connectorId: this.data.connectorId,
          managedResources: false,
          privateProperties: {
            receiverHttpEndpoint: this.getReceiverHttpEndpoint(this.form.value),
          },
          protocol: "dataspace-protocol-http",
          transferType: {
              contentType: "application/octet-stream",
              isFinite: true
          }
        }
      )
      .pipe(
        finalize(() => {
          this.loading = false;
          this.form.all.enable();
        }),
      )
      .subscribe({
        next: (transferProcessId) =>
          this.close({
            transferProcessId,
            contractId: this.data.contractId,
          }),
        error: (err) => {
          this.notificationService.showError('Failed initiating transfer!');
          console.error('Failed initiating transfer', err);
        },
      });
  }

  private getReceiverHttpEndpoint(
    formValue: ContractAgreementTransferDialogFormValue,
  ): string | undefined {
    return formValue.httpUrl;
  }

  private buildDataAddressDto(
    formValue: ContractAgreementTransferDialogFormValue,
  ): DataAddress {
    switch (formValue.dataAddressType) {
      case 'Custom-Data-Address-Json':
        return JSON.parse(formValue.dataDestination?.trim()!!);
      case 'Http':
        return this.httpRequestParamsMapper.buildHttpDataAddressDto(formValue);
      case 'AmazonS3':
        return JSON.parse(formValue.S3DataSource?.trim() ?? '');
      case 'AzureStorage':
        return JSON.parse(formValue.AzureStorageDataSource?.trim() ?? '');
      default:
        throw new Error(
          `Invalid Data Address Type ${formValue.dataAddressType}`,
        );
    }
  }

  private close(params: ContractAgreementTransferDialogResult) {
    this.dialogRef.close(params);
  }

  ngOnDestroy$ = new Subject();

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(null);
    this.ngOnDestroy$.complete();
  }
}
