import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {AppModule} from './modules/app/app.module';
import { defineCustomElements } from '@telekom/scale-components/loader';

if (environment.production) {
  enableProdMode();
}
defineCustomElements();
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
