import {Injectable} from '@angular/core';
import {
  Action,
  Constraint,
  Permission,
  Policy,
  PolicyDefinition, PolicyDefinitionOutput,
} from '../../edc-dmgmt-client';
import {cleanJson} from '../components/json-dialog/clean-json';
import {
  AtomicConstraint,
  EdcTypes,
  LiteralExpression,
  Operator,
} from './policy-type-ext';

@Injectable({
  providedIn: 'root',
})
export class PolicyDefinitionUtils {
  /**
   * Currently our policies only require a subset of the policy definition type
   *
   * A policy definition is regular, if it only uses one permission with atomic constraints
   *
   * @param policyDefinition
   */
  getPolicyIrregularities(policyDefinition: PolicyDefinitionOutput): string[] {
    policyDefinition = cleanJson(policyDefinition) as PolicyDefinitionOutput;

    const irregularities: string[] = [];

    const defaultKeys = ['edctype', '@type'];
    const checkKeys = <T>(
      prefix: string,
      value: T | null | undefined,
      allowedKeys: (keyof T)[],
    ) => {
      if (value != null) {
        Object.keys(value).forEach((key) => {
          if (
            !allowedKeys.includes(key as keyof T) &&
            !defaultKeys.includes(key)
          ) {
            irregularities.push(`${prefix}.${key} was set`);
          }
        });
      } else {
        irregularities.push(`${prefix} was not set`);
      }
    };

    const checkEdctype = <T, R>(
      prefix: string,
      value: T | null | undefined,
      typeChecker: (item: any) => item is R,
      isNotMessage: string,
      ifOkFn?: (value: R) => void,
    ) => {
      if (typeChecker(value)) {
        if (ifOkFn) ifOkFn(value);
      } else {
        irregularities.push(`${prefix} is not ${isNotMessage}`);
      }
    };

    const checkConstraint = (prefix: string, constraint: Constraint) => {
      checkEdctype(
        prefix,
        constraint,
        this.isAtomicConstraint,
        'an atomic constraint',
        (constraint) => {
          checkKeys(prefix, constraint, [
            'leftExpression',
            'operator',
            'rightExpression',
          ]);
          checkEdctype(
            `${prefix}.leftExpression`,
            constraint.leftExpression,
            this.isLiteralExpression,
            'a literal expression',
          );
          checkEdctype(
            `${prefix}.rightExpression`,
            constraint.rightExpression,
            this.isLiteralExpression,
            'a literal expression',
          );
        },
      );
    };

    const checkAction = (prefix: string, action: Action | undefined) => {
      checkKeys(prefix, action, ['type']);
      if (action && action.type !== 'USE') {
        irregularities.push(`${prefix}.type wasn't 'USE'`);
      }
    };

    const checkPermission = (prefix: string, permission: Permission) => {
      checkEdctype(
        prefix,
        permission,
        this.isPermission,
        'a permission',
        (permission) => {
          checkKeys(prefix, permission, ['constraints', 'action']);
          checkAction(`${prefix}.action`, permission.action);
          permission.constraints?.forEach((constraint: any, index: any) =>
            checkConstraint(`${prefix}.constraints[${index}]`, constraint),
          );
        },
      );
    };

    const checkPolicy = (prefix: string, policy: any) => {
      checkKeys(prefix, policy, ['type', 'permissions']);
      if (!policy.permissions?.length) {
        irregularities.push(`${prefix}.permissions is empty`);
      } else if (policy.permissions.length > 1) {
        irregularities.push(`${prefix}.permissions more than one entry`);
      }
      policy.permissions?.forEach((permission: any, index: any) =>
        checkPermission(`${prefix}.permissions[${index}]`, permission),
      );
    };
    checkPolicy('policy', policyDefinition.policy);
    return irregularities;
  }

  isPermission(obj: any): obj is Permission {
    return obj.edctype === EdcTypes.Permission;
  }

  buildPermission(patch: any): object {
    return {["odrl:constraint"]: {
        ['@type']: EdcTypes.Permission,
      ...patch,
    }
  }}

  isAtomicConstraint(obj: any): obj is AtomicConstraint {
    return obj.edctype === EdcTypes.AtomicConstraint;
  }

  buildAtomicConstraint(
    left: string,
    operator: string,
    right: string,
  ): object {
    return {
      ['@type']: 'Constraint',
      ['odrl:leftOperand']: left,
      ['odrl:rightOperand']: right,
      ["odrl:operator"] : {
        ["@id"]: "odrl:"+operator
      },
    };
  }

  isLiteralExpression(obj: any): obj is LiteralExpression {
    return obj.edctype === EdcTypes.LiteralExpression;
  }

  buildLiteralExpression(value: string): LiteralExpression {
    return {
      edctype: EdcTypes.LiteralExpression,
      value,
    };
  }
}
