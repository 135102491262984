<h1 mat-dialog-title>Create New Contract Definition</h1>

<mat-dialog-content class="w-[800px]">
  <div class="flex flex-col mt-[10px]">
    <!--  ID -->
    <mat-form-field
      *ngIf="form.group.controls.id; let ctrl"
      id="form-field-id"
      color="accent">
      <mat-label>ID</mat-label>
      <input matInput [formControl]="ctrl" />
      <mat-error *ngIf="ctrl.invalid && ctrl.errors?.pattern">{{
        validationMessages.invalidWhitespacesMessage
      }}</mat-error>
    </mat-form-field>

    <!-- Access Policy -->
    <policy-select
      label="Access Policy"
      [control]="form.group.controls.accessPolicy"
      [policies]="policies"></policy-select>

    <!-- Contract Policy -->
    <policy-select
      label="Contract Policy"
      [control]="form.group.controls.contractPolicy"
      [policies]="policies"></policy-select>

    <!-- Assets -->
    <mat-form-field
      *ngIf="form.group.controls.assets; let ctrl"
      id="form-field-contract-assets"
      color="accent">
      <mat-label>Assets</mat-label>
      <mat-select multiple [formControl]="ctrl" [compareWith]="isEqualId">
        <mat-option *ngFor="let asset of assets" [value]="asset">
          {{ asset.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex flex-row justify-end">
  <button mat-dialog-close mat-button [disabled]="loading">Cancel</button>

  <button
    color="primary"
    mat-raised-button
    [disabled]="loading || !form.group.valid"
    (click)="onSave()">
    Create
  </button>
</mat-dialog-actions>
