import {Injectable} from '@angular/core';
import {Observable, combineLatest, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {
  ContractDefinitionService,
  PolicyDefinitionService,
} from '../../../edc-dmgmt-client';
import {Fetched} from '../../models/fetched';
import {AssetServiceMapped} from '../../services/asset-service-mapped';
import {NotificationService} from '../../services/notification.service';
import {search} from '../../utils/search-utils';
import {ContractDefinitionCard} from '../contract-definition-cards/contract-definition-card';
import {ContractDefinitionCardBuilder} from '../contract-definition-cards/contract-definition-card-builder';

export interface ContractDefinitionList {
  contractDefinitionCards: ContractDefinitionCard[];
  numTotalContractDefinitions: number;
}

@Injectable({providedIn: 'root'})
export class ContractDefinitionPageService {
  constructor(
    private contractDefinitionService: ContractDefinitionService,
    private assetServiceMapped: AssetServiceMapped,
    private PolicyDefinitionService: PolicyDefinitionService,
    private notificationService: NotificationService,
    private contractDefinitionCardBuilder: ContractDefinitionCardBuilder,
  ) {}

  contractDefinitionPageData$(
    refresh$: Observable<any>,
    searchText$: Observable<string>,
  ): Observable<Fetched<ContractDefinitionList>> {
    return combineLatest([
      refresh$.pipe(switchMap(() => this.fetchCards())),
      searchText$,
    ]).pipe(
      map(([fetchedCards, searchText]) =>
        fetchedCards.map((cards) => ({
          contractDefinitionCards: this.filterCards(cards, searchText),
          numTotalContractDefinitions: cards.length,
        })),
      ),
    );
  }

  filterCards(
    cards: ContractDefinitionCard[],
    searchText: string,
  ): ContractDefinitionCard[] {
    return search(cards, searchText, (card) => {
      // @ts-ignore
      const cardCriteria = card?.criteria.map((it) => it.label);
      // @ts-ignore
      const searchTarget = card.criteria
        .flatMap((it) => it.values)
        .flatMap((it) => it.searchTargets)
    return [
      card.id,
      card.accessPolicy.policyDefinitionId,
      card.contractPolicy.policyDefinitionId,
      ...cardCriteria,
      ...searchTarget,
    ]});
  }

  fetchCards(): Observable<Fetched<ContractDefinitionCard[]>> {
    return combineLatest([
      this.contractDefinitionService.queryAllContractDefinitions(),
      this.assetServiceMapped.fetchAssets().pipe(
        catchError((err) => {
          console.warn('Failed fetching assets.', err);
          return of([]);
        }),
      ),
      this.PolicyDefinitionService.queryPolicyDefinitions().pipe(
        catchError((err) => {
          console.warn('Failed fetching policy definitions.', err);
          return of([]);
        }),
      ),
    ]).pipe(
      map(([contractDefinitions, assets, policyDefinitions]) => {
          return this.contractDefinitionCardBuilder.buildContractDefinitionCards(
            contractDefinitions,
            assets,
            policyDefinitions,
          )
        }
      ),
      Fetched.wrap({failureMessage: 'Failed fetching contract definitions'}),
    );
  }
}
