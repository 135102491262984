<mat-card *ngFor="let contractOffer of contractOffers" class="asset-card">
  <ng-container *ngIf="contractOffer; let asset">
    <mat-card-header>
      <edc-demo-contract-offer-icon
        mat-card-avatar
        [contractOffer]="contractOffer"></edc-demo-contract-offer-icon>
      <mat-card-title>
        <a
          class="link"
          href="javascript:void(0)"
          (click)="contractOfferClick.emit(asset)">
          {{ asset.name }}
        </a>
      </mat-card-title>
      <mat-card-subtitle>{{ asset.id }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content class="flex flex-col space-y-[16px]">
      <!-- Progress Bar -->
      <mat-progress-bar
        *ngIf="contractNegotiationService.isBusy(contractOffer)"
        color="primary"
        mode="indeterminate"></mat-progress-bar>

      <!-- Description -->
      <div
        *ngIf="asset.description"
        class="whitespace-pre-line truncate-lines-5">
        {{ asset.description }}
      </div>

      <!-- Tag List -->
      <div *ngIf="asset.keywords.length || asset.version">
        <mat-chip-list aria-label="Tags">
          <mat-chip *ngIf="asset.version" color="primary" selected>{{
            asset.version
          }}</mat-chip>
          <mat-chip *ngFor="let keyword of asset.keywords">{{
            keyword
          }}</mat-chip>
        </mat-chip-list>
      </div>
    </mat-card-content>
  </ng-container>
</mat-card>
