<mat-form-field class="grow form-field" color="accent">
  <mat-label>{{ label }}</mat-label>
  <mat-select [formControl]="control" [compareWith]="compareWith">
    <mat-option [value]="null">-</mat-option>
    <mat-option
      *ngFor="let item of dataCategory | dataSubcategoryItems"
      [value]="item">
      {{ item.label }}
    </mat-option>
  </mat-select>
</mat-form-field>
