import {Injectable} from '@angular/core';
import {
  ContractDefinitionInput,
  policyDefinitionId,
} from '../../edc-dmgmt-client';
import {ContractDefinitionEditorDialogFormValue} from '../components/contract-definition-editor-dialog/contract-definition-editor-dialog-form-model';
import {AssetProperties} from './asset-properties';
import {OperatorSymbols} from "src/modules/edc-demo/services/policy-type-ext";

@Injectable({
  providedIn: 'root',
})
export class ContractDefinitionBuilder {
  /**
   * Build {@link ContractDefinitionInput} from {@link ContractDefinitionEditorDialogFormValue}
   *
   * @param formValue form value
   * @return contract definition dto
   */
  buildContractDefinition(
    formValue: ContractDefinitionEditorDialogFormValue,
  ): ContractDefinitionInput {
    return {
      ["@id"]: formValue.id!.trim(),
      ["@context"]: {},
      ["@type"]: "ContractDefinition",
      accessPolicyId: policyDefinitionId(formValue.accessPolicy!),
      contractPolicyId: policyDefinitionId(formValue.contractPolicy!),
      assetsSelector: [
        {
          ["@type"] : "CriterionDto",
          operandLeft: 'https://w3id.org/edc/v0.0.1/ns/id',
          operator: formValue.assets!.length > 1 ? 'in' : OperatorSymbols.EQ,
          operandRight: formValue.assets!.map((it) => it.id),
        },
      ],
    };
  }
}
