<h1 mat-dialog-title>Create New Policy</h1>
<mat-dialog-content class="w-[800px]">
  <div class="flex flex-col mt-[10px]">
    <!--  ID -->
    <mat-form-field
      *ngIf="form.group.controls.id; let ctrl"
      id="form-field-id"
      color="accent">
      <mat-label>ID</mat-label>
      <input matInput [formControl]="ctrl" />
      <mat-error *ngIf="ctrl.invalid && ctrl.errors?.pattern">{{
        validationMessages.invalidWhitespacesMessage
      }}</mat-error>
    </mat-form-field>

    <!-- Policy Type -->
    <mat-form-field color="accent">
      <mat-label>Type</mat-label>
      <mat-select [formControl]="form.group.controls.policyType">
        <mat-option value="Time-Period-Restricted">
          Time-Period-Restricted
        </mat-option>
        <mat-option value="Connector-Restricted-Usage">
          Connector-Restricted-Usage
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Date Range -->
    <mat-form-field
      *ngIf="
        form.policyType == 'Time-Period-Restricted' &&
          form.group.controls.range;
        let ctrl
      "
      color="accent">
      <mat-label>Time-Period</mat-label>
      <mat-date-range-input [formGroup]="ctrl" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date" />
        <input matEndDate formControlName="end" placeholder="End date" />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="ctrl.invalid">{{
        validationMessages.invalidDateRangeMessage
      }}</mat-error>
    </mat-form-field>

    <!-- Connector ID -->
    <mat-form-field
      *ngIf="form.policyType == 'Connector-Restricted-Usage'"
      color="accent">
      <mat-label>Connector ID</mat-label>
      <input
        matInput
        placeholder="https://other-connector.com"
        [formControl]="form.group.controls.connectorId" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="flex flex-row justify-end">
  <button mat-dialog-close mat-button [disabled]="loading">Cancel</button>

  <button
    color="primary"
    mat-raised-button
    [disabled]="loading || !form.group.valid"
    (click)="onSave()">
    Create
  </button>
</mat-dialog-actions>
